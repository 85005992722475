import React from 'react';
import { graphql } from 'gatsby';
import img from 'gatsby-image';

import {
    IntroHolder,
    TwoColumns,
    Content,
    Col,
} from '@bahaide/shared/components/InnerPage';

import InnerPage from '../../../components/InnerPage';

const DatenUndFakten = ({ data }) => (
    <InnerPage site="hda" title="Architektur" description="">
        <IntroHolder
            title="Architektur"
            picture={data.introImage}
            breadcrumbs={{
                parent: 'Haus der Andacht',
                'current-item': 'Architektur',
            }}
        />

        <TwoColumns>
            <Content>
                <h2>Gestaltungselemente am Tempel</h2>
                <p>
                    In der Kuppel des Hauses der Andacht findet sich ein Symbol.
                    Diese Kalligraphie ist die künstlerisch gestaltete Anrufung
                    „Yá Bahá’u‘l-Abhá“, ein arabischer Vers, der sich übersetzen
                    lässt mit „O du, Herrlichkeit des Allherrlichen!“
                </p>
                <p>
                    <figure>
                        <img
                            alt=""
                            src={
                                data.gestaltungselementeImage.childImageSharp
                                    .fixed.src
                            }
                        />
                    </figure>
                </p>
                <p>
                    <blockquote
                        className="serif h2"
                        style={{
                            marginTop: 100,
                            maxWidth: 360,
                        }}
                    >
                        <q>
                            „Obwohl der Mashriqu'l-Adhkár, äußerlich betrachtet,
                            ein stoffliches Gefüge ist, hat er geistige Wirkung.
                            Er schmiedet Bande der Einheit von Herz zu Herz; er
                            ist ein Sammelpunkt für die Menschenseelen.“
                        </q>
                    </blockquote>
                    <cite className="h3">‘Abdu’l-Bahá</cite>
                </p>
            </Content>
            <aside id="sidebar">
                <div className="widget">
                    <h3>Technische Daten</h3>
                    <Col>
                        <figure className="caption-right">
                            <img
                                alt=""
                                src={
                                    data.technical1Image.childImageSharp.fixed
                                        .src
                                }
                            />
                            <figcaption>
                                Durchmesser der Grundfläche des Umgangs 48 m
                            </figcaption>
                        </figure>
                    </Col>
                    <Col>
                        <figure className="caption-left">
                            <img
                                alt=""
                                src={
                                    data.technical2Image.childImageSharp.fixed
                                        .src
                                }
                            />
                            <figcaption>
                                Durchmesser der Grundfläche des Kuppelraums 25 m
                            </figcaption>
                        </figure>
                    </Col>
                    <Col>
                        <figure className="caption-right">
                            <img
                                alt=""
                                src={
                                    data.technical3Image.childImageSharp.fixed
                                        .src
                                }
                            />
                            <figcaption>Höhe vom Erdboden 28 m</figcaption>
                        </figure>
                    </Col>
                    <Col>
                        <figure className="caption-left">
                            <img
                                alt=""
                                src={
                                    data.technical4Image.childImageSharp.fixed
                                        .src
                                }
                            />
                            <figcaption>
                                27 nach oben strebende Rippen zwischen denen 540
                                rauten-förmige Glasfensteröffnungen angeordnet
                                sind
                            </figcaption>
                        </figure>
                    </Col>
                    <Col>
                        <figure className="caption-right">
                            <img
                                alt=""
                                src={
                                    data.technical5Image.childImageSharp.fixed
                                        .src
                                }
                            />
                            <figcaption>
                                Verglasungsfläche der Kuppel 386 m<sup>2</sup>
                            </figcaption>
                        </figure>
                    </Col>
                    <Col>
                        <figure className="caption-left">
                            <img
                                alt=""
                                src={
                                    data.technical6Image.childImageSharp.fixed
                                        .src
                                }
                            />
                            <figcaption>
                                Verglasungsfläche des Umgangs 505 m<sup>2</sup>
                            </figcaption>
                        </figure>
                    </Col>
                    <Col>
                        <figure className="caption-right">
                            <img
                                alt=""
                                src={
                                    data.technical7Image.childImageSharp.fixed
                                        .src
                                }
                            />
                            <figcaption>
                                Gewicht der mittleren Pfeilerteile 14 000 kg
                            </figcaption>
                        </figure>
                    </Col>
                    <Col>
                        <figure className="caption-left">
                            <img
                                alt=""
                                src={
                                    data.technical8Image.childImageSharp.fixed
                                        .src
                                }
                            />
                            <figcaption>
                                Gewicht der oberen Pfeilerteile 8 000 kg
                            </figcaption>
                        </figure>
                    </Col>
                </div>
            </aside>
        </TwoColumns>

        <p className="separator" />

        <section className="add-pages-block add-pages-block--transparent">
            <div className="container">
                <div className="top-holder">
                    <h2 className="h1">Historie</h2>
                    <figure className="small">
                        <img
                            alt=""
                            src={data.historieImage.childImageSharp.fixed.src}
                        />
                    </figure>
                </div>
                <div className="pages-list">
                    <ul className="timeline">
                        <li>
                            <div className="direction-r">
                                <div className="flag-wrapper">
                                    <span className="flag">1956</span>
                                </div>
                                <figure>
                                    <img
                                        alt=""
                                        src={
                                            data.architectImage.childImageSharp
                                                .fixed.src
                                        }
                                    />
                                </figure>
                                <div className="desc">
                                    Nach einem Architektenwettbewerb fällt die
                                    Entscheidung für den Entwurf des Architekten
                                    Teuto Rocholl
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="direction-l">
                                <div className="flag-wrapper">
                                    <span className="flag">1960</span>
                                </div>
                                <figure>
                                    <img
                                        alt=""
                                        src={
                                            data.grundsteinImage.childImageSharp
                                                .fixed.src
                                        }
                                    />
                                </figure>
                                <div className="desc">Grundsteinlegung</div>
                            </div>
                        </li>
                        <li>
                            <div className="direction-r">
                                <div className="flag-wrapper">
                                    <span className="flag">1962</span>
                                </div>
                                <figure>
                                    <img
                                        alt=""
                                        src={
                                            data.constructionImage
                                                .childImageSharp.fixed.src
                                        }
                                    />
                                </figure>
                                <div className="desc">Richtfest</div>
                            </div>
                        </li>
                        <li>
                            <div className="direction-l">
                                <div className="flag-wrapper">
                                    <span className="flag">1964</span>
                                </div>
                                <figure>
                                    <img
                                        alt=""
                                        src={
                                            data.einweihungImage.childImageSharp
                                                .fixed.src
                                        }
                                    />
                                </figure>
                                <br />
                                <figure>
                                    <img
                                        alt=""
                                        src={
                                            data.einweihung2Image
                                                .childImageSharp.fixed.src
                                        }
                                    />
                                </figure>
                                <div className="desc">Einweihung</div>
                            </div>
                        </li>
                        <li>
                            <div className="direction-r">
                                <div className="flag-wrapper">
                                    <span className="flag">1987</span>
                                </div>
                                <figure>
                                    <img
                                        alt=""
                                        src={
                                            data.denkmalImage.childImageSharp
                                                .fixed.src
                                        }
                                    />
                                </figure>
                                <div className="desc">
                                    Das Haus der Andacht wurde vom Land Hessen
                                    zum hessischen Kulturdenkmal erklärt
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="direction-l">
                                <div className="flag-wrapper">
                                    <span className="flag">2014</span>
                                </div>
                                <figure>
                                    <img
                                        alt=""
                                        src={
                                            data.feierImage.childImageSharp
                                                .fixed.src
                                        }
                                    />
                                </figure>
                                <div className="desc">
                                    Feierlichkeiten 50 Jahre Bahá’í Haus der
                                    Andacht
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </section>
    </InnerPage>
);

export default DatenUndFakten;

export const pageQuery = graphql`
    query {
        introImage: file(relativePath: { eq: "IMG_7390.jpg" }) {
            ...fluidImage
        }
        gestaltungselementeImage: file(
            relativePath: { eq: "hda-innere-groesster-name.jpg" }
        ) {
            ...fixedImage
        }
        historieImage: file(relativePath: { eq: "HausDerAndacht_1965.jpg" }) {
            ...fixedImage
        }
        architectImage: file(relativePath: { eq: "04_Grundstein-04.jpg" }) {
            ...fixedImage
        }
        grundsteinImage: file(relativePath: { eq: "04_Grundstein-08.jpg" }) {
            ...fixedImage
        }
        constructionImage: file(
            relativePath: { eq: "hda-construction-collage.jpg" }
        ) {
            ...fixedImage
        }
        einweihungImage: file(relativePath: { eq: "06_Einweihung-01.jpg" }) {
            ...fixedImage
        }
        einweihung2Image: file(relativePath: { eq: "06_Einweihung-04.jpg" }) {
            ...fixedImage
        }
        denkmalImage: file(relativePath: { eq: "1395940-master.jpg" }) {
            ...fixedImage
        }
        feierImage: file(relativePath: { eq: "_7037394.JPG" }) {
            ...fixedImage
        }
        technicalfeatureImage: file(relativePath: { eq: "hda-struktur.jpg" }) {
            ...fixedImage
        }
        technical1Image: file(relativePath: { eq: "DJI_0041.jpg" }) {
            ...fixedImage
        }
        technical2Image: file(relativePath: { eq: "HDA-50.jpg" }) {
            ...fixedImage
        }
        technical3Image: file(relativePath: { eq: "DSC08383.jpg" }) {
            ...fixedImage
        }
        technical4Image: file(relativePath: { eq: "DSC08404.jpg" }) {
            ...fixedImage
        }
        technical5Image: file(relativePath: { eq: "DSC08322.jpg" }) {
            ...fixedImage
        }
        technical6Image: file(relativePath: { eq: "DSC08306.jpg" }) {
            ...fixedImage
        }
        technical7Image: file(relativePath: { eq: "DSC08301.jpg" }) {
            ...fixedImage
        }
        technical8Image: file(relativePath: { eq: "DSC08438.jpg" }) {
            ...fixedImage
        }
    }
`;
